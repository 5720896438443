"use client";

import styled from "styled-components";
import Image from "next/image";
import { useEffect, useState, useRef } from "react";
import blur from '@/public/images/static/blur.jpg';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import {useGSAP} from "@gsap/react";

gsap.registerPlugin(ScrollTrigger);

export const ImgUpdownParallax = ({
                                    src,
                                    srcSm,
                                    position,
                                    objectFit,
                                    height,
                                    width,
                                    alt,
                                    left,
                                    margin,
                                    right,
                                    top,
                                    bottom,
                                    transition,
                                    dataSpeed
                                }) => {
    const [deviceWidth, setDeviceWidth] = useState(0);
    const ref = useRef(null);
    const imgRef = useRef(null);

    // Set device width
    useEffect(() => {


        window.addEventListener('load', function () {
            setDeviceWidth(document.querySelector(' .container').offsetLeft)

        })
        window.addEventListener('resize', function () {
            setDeviceWidth(document.querySelector(' .container').offsetLeft)

        })
        setDeviceWidth(document.querySelector(' .container').offsetLeft)
    }, [])

    useGSAP(() => {
        // ScrollTrigger.refresh();

        gsap.to(imgRef.current, {
            y: '-10%',
            duration: 1.5,
            scrollTrigger: {
                trigger: ref.current,
                scrub: 2,
                start: "top bottom",
                end: "bottom top",
                ease: "none",
            }
        });
    }, []);

    return (
        <StyledImg
            ref={ref}
            className='global-image'
            objectFit={objectFit}
            margin={margin}
            position={position}
            left={left}
            right={right}
            top={top}
            bottom={bottom}
            height={height}
            width={width}
            transition={transition}
            dataSpeed={dataSpeed}
        >
            <div
                ref={imgRef}
                style={{ width: '100%', height: '115%', position: 'relative' }}
            >
                <Image
                    blurDataURL="data:image/jpeg;base64,/9j/4AAQSkZJRgABAQE..."
                    placeholder={"blur"}
                    alt={alt ? alt : src}
                    objectFit={'cover'}
                    layout={'fill'}
                    src={src ? (deviceWidth > 600 ? src : (srcSm || src)) : blur}
                />
            </div>
        </StyledImg>
    );
};

const StyledImg = styled.div`
    position: ${props => props.position || 'absolute'};
    height: ${props => props.height || '100%'};
    width: ${props => props.width || '100%'};
    top: ${props => props.top || 0};
    left: ${props => props.left || 0};
    bottom: ${props => props.bottom || 0};
    right: ${props => props.right || 0};
    margin: ${props => props.margin || 0};
    overflow: hidden;

    img {
        ${props => props.transition && `transition: ${props.transition}`};
    }
`;
