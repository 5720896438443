'use client'
import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import Link from 'next/link'
import {Img} from "@/components/Img";
import {offwhite, Transition, white} from "@/styles/globalStyleVars";
import Button from "@/components/Button";
import {ImgLeftParallax} from "@/components/LeftRightParallax";
import {ImgUpdownParallax} from "@/components/ImageUpdownParallax";
import reactHtmlParser from "react-html-parser";
import {
    CURSOR_COLOR,
    CURSOR_HIDE,
    CURSOR_REVEAL,
    CURSOR_SHOW,
    CURSOR_TEXT,
    CURSOR_VIDEO,
    DispatchCursor
} from "haspr-cursor";

const MyComponent = ({data}) => {
    const dispatch = DispatchCursor()
    return (
        <StyledComponent onMouseEnter={CURSOR_SHOW} onMouseLeave={CURSOR_HIDE} className={'virtual-showroom'}>
            <Container fluid onMouseEnter={() => CURSOR_COLOR('GRAY')} onMouseLeave={() => CURSOR_COLOR('END')}>
                <Row>
                    <Col lg={6} className={'left'}>
                        <div className={'image-wrapper'}>
                            <ImgUpdownParallax src={data?.posts?.list?.[0]?.images?.[0]?.full_path}/>
                        </div>
                        <div className={'body'}>
                            <div className={'body__wrapper'}>
                                <h4 className={'split-left'}>{reactHtmlParser(data?.posts?.list?.[0]?.data?.subtitle)}</h4>
                                <p>{reactHtmlParser(data?.posts?.list?.[0]?.data?.short_desc)}</p>
                            </div>
                            <div onMouseEnter={() => CURSOR_TEXT(dispatch, 'Click', 'Philippine Gray')} onMouseLeave={() => CURSOR_TEXT(dispatch, 'END')}>
                                <Link href={data?.posts?.list?.[0]?.data?.description}>Explore</Link>
                            </div>
                        </div>
                    </Col>
                    <Col lg={6} className={'right'}>
                        <div className={'image-wrapper'}>
                            <ImgUpdownParallax src={data?.posts?.list?.[1]?.images?.[0]?.full_path}/>
                        </div>
                        <div className={'body'}>
                            <div className={'body__wrapper'}>
                                <h4 className={'split-left'}>{reactHtmlParser(data?.posts?.list?.[1]?.data?.subtitle)}</h4>
                                <p>{reactHtmlParser(data?.posts?.list?.[1]?.data?.short_desc)}</p>
                            </div>
                            <div onMouseEnter={() => CURSOR_TEXT(dispatch, 'Click', '#ffffff')} onMouseLeave={() => CURSOR_TEXT(dispatch, 'END')}>
                                <Link href={data?.posts?.list?.[1]?.data?.description}>Explore</Link>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  &.virtual-showroom{
    background: black;
  }
  .image-wrapper {
    position: relative;
    padding-top: calc(520 / 680 * 100%);

    img {
      transform: scale(1);
      transition: 0.8s ${Transition} transform;
    }
  }

  .col-lg-6 {
    padding: 0;
  }

  .left, .right {
    position: relative;

    .body {
      position: absolute;
      top: 0;
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 140px;
      padding-top: 18%;

      &__wrapper {
        width: 60%;

        h4 {
          color: ${white};
          text-align: center;
          margin-bottom: 20px;
          letter-spacing: -0.96px;

          span {
            font-style: italic;
          }
        }

        p {
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px;
          color: ${white};
          text-align: center;
        }
      }

      a {
        color: ${offwhite};
        position: relative;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: -0.42px;

        &:after {
          position: absolute;
          content: '';
          bottom: -5px;
          left: 0;
          height: 1px;
          width: 0;
          background: ${offwhite};
          transition: 0.8s ${Transition} width;
        }
      }
    }
  }

  .left {
    &:hover {
      a {
        cursor: pointer;
        color: ${offwhite} !important;

        &:hover {
          color: ${offwhite} !important;
        }

        &:after {
          width: 100%;
          transition: 0.5s ${Transition} width;
        }
      }

      img {
        transform: scale(1.05);
        transition: 0.5s ${Transition} transform;
      }
    }
  }

  .right {
    &:hover {
      a {
        cursor: pointer;
        color: ${offwhite} !important;

        &:hover {
          color: ${offwhite} !important;
        }

        &:after {
          width: 100%;
          transition: 0.5s ${Transition} width;
        }
      }

      img {
        transform: scale(1.05);
        transition: 0.5s ${Transition} transform;
      }
    }
  }

  @media (max-width: 767px) {
    .row{
        margin: 0!important;
    }
    .left, .right {
      position: relative;

      .body {
        gap: 40px;
        padding-top: 15%;
      }
    }
    .left{
      .image-wrapper{
        padding-top: calc(520 / 680* 100%);
      }
    }
  }
`;

export default MyComponent;
